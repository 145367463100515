import axios from 'axios';

async function createAdmin() {
    return axios.post(process.env.REACT_APP_ONE_URL + '/ComWebPortal/CreateAdmin');
}

async function getStatus() {
    return axios.post(process.env.REACT_APP_ONE_URL + '/ComWebPortal/CustomerStatus');
}

async function isAdvancedPortalEnabled() {
    return axios.post(process.env.REACT_APP_ONE_URL + '/ComWebPortal/IsCompanyLive');
}
export {createAdmin, isAdvancedPortalEnabled, getStatus }