import { IonCard, IonCol, IonGrid, IonInput, IonRow, IonImg, IonCardContent, IonItem, IonIcon, IonButton, useIonLoading, useIonAlert, IonLabel, IonContent } from '@ionic/react';
import { mail, lockClosed } from "ionicons/icons";
import { useAuth } from '../../../contexts/auth';
import style from './login.module.scss';
import { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../index";
import { InteractionStatus } from '@azure/msal-browser';

const Login: React.FC = () => {
    const { signIn, authNewFlow } = useAuth()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [currentYear] = useState((new Date()).getFullYear())

    const [presentLoading, dismissLoading] = useIonLoading()
    function showLoading() {
        presentLoading({
            message: 'Signing in...',
            spinner: 'lines'
        })
    }

    const [presentAlert] = useIonAlert()
    function showAlert(message: string) {
        presentAlert(message)
    }

    function handleSignin(username: string, password: string) {
        if (authNewFlow && (inProgress === InteractionStatus.None && !isAuthenticated)) {
            instance.loginRedirect(loginRequest);
        }
        else {
            showLoading()
            signIn({ username: username, password: password })
                .then(() => {
                    dismissLoading()
                    window.location.href = '/home'
                })
                .catch((error) => {
                    dismissLoading()
                    showAlert(error)
                })
        }
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
        if (event.key === 'Enter') {
            handleSignin(email, password);
        }
    };

    return (
        <IonContent fullscreen>
            <div className={`${style.login}`} style={{ backgroundImage: `url("/assets/Login_Page_Background.png")` }}>
                <IonGrid className={style.container}>
                    <IonRow>
                        <IonCard className={style.center}>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol className={style.marginItems} >
                                        <IonRow className={style.img}>
                                            <a href="https://www.goenumerate.com">
                                                <img alt='' src="/assets/Enumerate_Logo_Color_RGB_375x41.png" />
                                            </a>
                                        </IonRow>
                                        <IonRow hidden={authNewFlow}>
                                            <IonItem className={style.input} lines='none'>
                                                <IonIcon icon={mail} />
                                                <IonInput type='email' value={email} placeholder='Email Address'
                                                    onIonChange={e => setEmail(e.detail.value!)} onKeyPress={handleKeyPress} />
                                            </IonItem>
                                        </IonRow>
                                        <IonRow hidden={authNewFlow}>
                                            <IonItem className={style.input} lines='none'>
                                                <IonIcon icon={lockClosed} />
                                                <IonInput type='password' value={password} placeholder='Password'
                                                    onIonChange={e => setPassword(e.detail.value!)} onKeyPress={handleKeyPress} />
                                            </IonItem>
                                        </IonRow>
                                        <IonRow>
                                            <IonButton className={style.loginButton} color='button'
                                                onClick={() => handleSignin(email, password)}>Log in</IonButton>
                                        </IonRow>
                                        <IonRow>
                                            <IonItem />
                                        </IonRow>
                                        <IonRow className={style.chrome}>
                                            <IonCol size='12'>
                                                <p>For the Best Experience, We Recommend</p>
                                            </IonCol>
                                            <IonCol size='12'>
                                                <a href='https://www.google.com/chrome/browser/desktop/index.html' target='_blank' rel="noreferrer">
                                                    <IonImg src={process.env.PUBLIC_URL + '/assets/logo_chrome.png'} alt='Chrome Logo' />
                                                    <p>Download Now</p>
                                                </a>
                                            </IonCol>
                                        </IonRow>
                                    </IonCol>
                                </IonRow>
                            </IonCardContent>
                        </IonCard>
                    </IonRow>
                    <IonRow className={style.footer}>
                        <p>©{currentYear} - Enumerate</p>
                    </IonRow>
                </IonGrid>
            </div>
        </IonContent>
    )
}

export default Login