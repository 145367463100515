import { setupAccessTokenInterceptor, setupJwtInterceptor } from './services/interceptors/jwt-interceptor';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { AuthProvider, useAuth } from './contexts/auth';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Login from './pages/login/desktop/login';
import React from 'react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Home from './pages/home/home';
import { useFlags } from 'launchdarkly-react-client-sdk';

setupIonicReact();

const RoutesB2C: React.FC = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <Route path="/">
          <Redirect to="/home" />
        </Route>
        <Route exact path="/home" render={() => <Home />} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Route path="/">
          <Redirect to="/login" />
        </Route>
        <Route exact path="/login" render={() => <Login />} />
      </UnauthenticatedTemplate>
    </>
  )
}

const Routes: React.FC = () => {
  const { signed } = useAuth()

  const handleLogin = () =>
    signed ? <Redirect to="/home" /> : <Login />

  const render = (component: any) =>
    signed ? component : <Redirect to="/login" />

  return (
    <>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <Route exact path="/login" render={() => handleLogin()} />
      <Route exact path="/home" render={() => render(<Home />)} />
    </>
  )
}

const App: React.FC = () => {
  const flags = useFlags();
  let routes;
  console.log('authNewFlow', flags.authNewFlow);
  if (flags.authNewFlow) { //Flag to decide if we are going to use the new or old authentication
    setupAccessTokenInterceptor();
    routes = <RoutesB2C />;
  } else {
    setupJwtInterceptor()
    routes = <Routes />;
  }

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <AuthProvider>
            {routes}
          </AuthProvider>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
}

export default App;
