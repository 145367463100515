import { IonButton, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonLabel, IonPage, IonRow, IonTitle, IonToolbar, isPlatform, useIonLoading } from '@ionic/react';
import logo from '../../assets/images/Enumerate_Logo_Color_RGB.png';
import eventImage from '../../assets/images/Events_Image.png';
import AppContainer from '../../components/AppContainer/AppContainer';
import { useAuth } from '../../contexts/auth';
import style from './home.module.scss';

import { useState } from 'react';
import HomeMobile from './mobile/homeMobile';
import { logOutOutline } from 'ionicons/icons';
import { useMsal } from '@azure/msal-react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const Home: React.FC = () => {
    const { signOut, authNewFlow } = useAuth();
    const [presentLoading, dismissLoading] = useIonLoading();
    const { instance } = useMsal();

    const [currentYear] = useState((new Date()).getFullYear());

    function handleSignout() {
        presentLoading({ spinner: 'lines', message: 'Signing out' })
        setTimeout(() => {
            if(authNewFlow){
                const activeAccount = instance.getActiveAccount();
                instance.logoutRedirect({ account: activeAccount});
            }
            else {
                signOut()
                dismissLoading()
                window.location.href = '/login'
            }
        }, 500)
    }

    const desktop = (
        <IonPage className={style.homePage}>
            {/* Header */}
            <IonHeader>
                <IonToolbar class={style.toolbar} >
                    <a href="https://www.goenumerate.com/" className={style.logo}>
                        <img alt="Enumerate Logo" src={logo} />
                    </a>
                    <IonButton fill='clear' slot='end' onClick={handleSignout} title='Log out'>
                        <IonIcon slot="icon-only" icon={logOutOutline}></IonIcon>
                    </IonButton>
                </IonToolbar>
            </IonHeader>

            {/* Content */}
            <IonContent class={style.homeContent} fullscreen>
                <AppContainer />

                <IonGrid className={style.container}>
                    <IonRow className={style.header}>
                        <IonCol>
                            <IonLabel>Making Community Management Easier.</IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow className={style.content}>
                        <IonCol className={style.image}>
                            <div>
                                <img alt="Event Image" src={eventImage} />
                            </div>
                        </IonCol>
                        <IonCol>
                            <IonLabel className={style.title}>Educate and Engage with Enumerate</IonLabel>
                            <IonLabel className={style.description}>
                                Throughout the year, we hold educational events and webinars along with attending and sponsoring some of the industry’s most engaging events. Stay connected with Enumerate across our social accounts and follow our event schedule below.
                            </IonLabel>
                            <IonButton fill='outline' shape='round' onClick={() => { window.location.href = 'https://www.goenumerate.com/events' }}>Learn more</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                {/* Footer */}
                <IonFooter>
                    <IonTitle>@{currentYear}. Enumerate. All rights reserved.</IonTitle>
                </IonFooter>
            </IonContent>
        </IonPage >
    )

    return isPlatform('desktop') ? desktop : <HomeMobile currentYear={currentYear} />;
};

export default Home;