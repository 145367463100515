import { stringify } from "querystring";
import  secureLocalStorage  from  "react-secure-storage";
import { Login, User } from "../contexts/auth";

const setUserStorage = (user: User) => 
    secureLocalStorage.setItem('user', JSON.stringify(user))

const setRenewTokenStorage = (isRenewing: number) => 
    secureLocalStorage.setItem('isRenewing', JSON.stringify(isRenewing))

const getUserStorage = () => {
    let userStorage = secureLocalStorage.getItem('user')
    if (userStorage)
        return JSON.parse(userStorage.toString()) as User
    return null
}
const getRenewTokenStorage = () => {
    let renewTokenStorage = secureLocalStorage.getItem('isRenewing')
    if (renewTokenStorage)
        return JSON.parse(renewTokenStorage.toString()) as number
    return null
}

const setTokenStorage = (token :string) =>
    secureLocalStorage.setItem('token', token)

    
const getTokenStorage = () => {
    let token = secureLocalStorage.getItem('token')
    if (token)
        return token.toString()
    return null
}

const getLoginStorage = () => {
    let login = secureLocalStorage.getItem('login')
    if (login)
        return JSON.parse(login.toString()) as Login
    return null
}

const removeUserStorage = () => 
    secureLocalStorage.removeItem('user')

const removeRenewTokenStorage = () => 
    secureLocalStorage.removeItem('isRenewing')


export { setUserStorage, getUserStorage, getLoginStorage, removeUserStorage, getTokenStorage, setTokenStorage, setRenewTokenStorage, getRenewTokenStorage, removeRenewTokenStorage }