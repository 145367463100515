import { setUserStorage } from '../utils/secure-storage'
import { User } from '../contexts/auth';
import api from '../services/api'

export interface Auth {
    signed: boolean
    user: User | null
    message: string
}

function signIn(username: string, password: string) : Promise<Auth> {
    return new Promise<Auth>((resolve, reject) => {
        api.post(process.env.REACT_APP_ONE_URL + '/Account/Login?shouldReload=false', {
            email: username,
            password: password
        }, { withCredentials: true })
        .then(response => {
            const isJson = response.headers['content-type']?.includes('application/json')
            const error = isJson && response.data.errorMessage
            
            if (error) {
                throw Error(response.data.errorMessage)
            }

            const data = isJson && response.data.token
    
            if (!data) {
                throw Error('Unable to get you signed in.')
            }
    
            let user = data as User
            setUserStorage(user)

            resolve({
                message: '',
                signed: true,
                user: user
            })
        })
        .catch(error => {
            reject(error.message)
        })
    })
}

export { signIn }