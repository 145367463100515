import { IonButton, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonLabel, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import style from './homeMobile.module.scss';
import logo from '../../../assets/images/Enumerate_Logo_Color_RGB_Mobile.png';
import eventMobileImage from '../../../assets/images/Events.png';
import AppContainer from '../../../components/AppContainer/AppContainer';

interface HomeProps {
  currentYear: number
}

const HomeMobile: React.FC<HomeProps> = (props) => {
  return (
    <IonPage className={style.homePageMobile}>
      {/* Header */}
      <IonHeader>
        <IonToolbar class={style.toolbar} >
          <a href="https://www.goenumerate.com/" className={style.logo}>
            <img alt="Enumerate Logo" src={logo} />
          </a>
        </IonToolbar>
      </IonHeader>

      {/* Content */}
      <IonContent class={style.homeContent} fullscreen>
        <AppContainer />

        <IonGrid className={style.container}>
          <div className={style.image}>
            <img alt="Event Image" src={eventMobileImage} />
          </div>
          <IonRow className={style.content}>
            <IonCol>
              <IonLabel className={style.slogan}>Making Community Management Easier.</IonLabel>
              <IonLabel className={style.title}>Educate and Engage with Enumerate</IonLabel>
              <IonLabel className={style.description}>
                Throughout the year, we hold educational events and webinars along with attending and sponsoring some of the industry’s most engaging events. Stay connected with Enumerate across our social accounts and follow our event schedule below.
              </IonLabel>
              <IonRow>
                <IonCol className={style.learnMore}>
                  <IonButton>Learn more</IonButton>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>

        {/* Footer */}
        <IonFooter>
          <IonTitle>@{props.currentYear}. Enumerate. All rights reserved.</IonTitle>
        </IonFooter>

      </IonContent>
    </IonPage>
  )
};

export default HomeMobile;
