import { getUserStorage, setTokenStorage, setUserStorage, getRenewTokenStorage, setRenewTokenStorage, removeRenewTokenStorage } from '../../utils/secure-storage';
import { User } from '../../contexts/auth';
import axios from 'axios';
import { loginRequest, msalInstance } from '../..';
import secureLocalStorage from 'react-secure-storage'


export async function setupJwtInterceptor() {
    axios.interceptors.request.use(request => {
        // add auth header with jwt if account is logged in and request is to the api url
        const userStorage = getUserStorage()
        const isLoggedIn = userStorage?.Token

        if (isLoggedIn) {
            let jwt = JSON.parse(window.atob(userStorage.Token.split('.')[1]))
            let nowInSeconds = Math.floor(Date.now() / 1000)
            let thirtyMinutesInSeconds = 1800
            let isRenewing = getRenewTokenStorage()
         
            console.log(nowInSeconds > (jwt.exp - thirtyMinutesInSeconds))

            if (nowInSeconds > jwt.exp) {
                // Token is expired, send them to the login screen
                secureLocalStorage.clear()
                window.location.href = '/login'

            } else if ((nowInSeconds > (jwt.exp - thirtyMinutesInSeconds)) && !isRenewing) {
                setRenewTokenStorage(nowInSeconds)
                // Token has been around for 3.5 hours and JWT expires in 4 hours, lets renew it.
                // Token expiration time is set in Pyramid's AuthenticationService.cs in the AuthenticateAndProvideJwt method.
                axios.get(process.env.REACT_APP_ONE_URL + '/Account/RenewToken', {
                    headers: {
                        'Authorizarion': `Bearer ${userStorage.Token}`
                    }
                })
                    .then((response) => response.data)
                    .then((result) => {
                        if (result && result.token){
                            setUserStorage(result.token as User)
                            removeRenewTokenStorage()
                        }
                        console.log("aaaaaaaaaa")
                            

                    })
                    .catch(reason => {
                        console.error("Failed to renew token:", reason)
                    })
            }

            if (isRenewing && (isRenewing < (nowInSeconds - 30))) {
                removeRenewTokenStorage()
            }

            request.headers = {
                Authorization: `Bearer ${userStorage.Token}`
            }
        }

        return request;
    });

}


export async function setupAccessTokenInterceptor() {
    axios.interceptors.request.use(async request => {
        const account = msalInstance.getActiveAccount();
        let accessToken = '';
        if (!account) {
            console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
        else {
            const response = await msalInstance.acquireTokenSilent({
                ...loginRequest,
                account: account
            });
            accessToken = response.accessToken;
        }

        request.headers = {
            Authorization: `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin': '*'
        }

        setTokenStorage(accessToken);
        return request;
    });
}