import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCol, IonGrid, IonLabel, IonLoading, IonRow, isPlatform } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Login, User, useAuth } from '../../contexts/auth';
import { generateSSO } from '../../services/nabr';
import { createAdmin, getStatus, isAdvancedPortalEnabled } from '../../services/portal';
import { addHours } from '../../utils/date';
import { getLoginStorage, getTokenStorage, getUserStorage } from '../../utils/secure-storage';
import style from './AppContainer.module.scss';

import centralLogo from '../../assets/images/Central_Only_Logo_RGB.png';
import engageLogo from '../../assets/images/Engage_Only_Logo_RGB.png';
import portalsLogo from '../../assets/images/Portals_Only_Logo_RGB.png';

import centralImage from '../../assets/images/Central_Card_Image.png';
import engageImage from '../../assets/images/Engage_Card_Image.png';
import portalsImage from '../../assets/images/Portal_Card_Image.png';

interface App {
    name: string,
    description: string,
    logo: string,
    img: string
    url: string | undefined,
    learnMore?: string,
    hasAccess?: boolean,
    handler?: () => void
}

const AppContainer: React.FC = () => {
    const { signIn, authNewFlow } = useAuth()
    const [user] = useState<User | null>(getUserStorage())
    const [login] = useState<Login | null>(getLoginStorage())
    const [portalAccess, setPortalAccess] = useState(true)
    const { instance } = useMsal();
    const [engageAccess, setEngageAccess] = useState(false)
    const [engageSSO, setEngageSSO] = useState("")
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        const email = authNewFlow ? instance.getActiveAccount()?.username : user?.EmailAddress;

        async function checkNabrAccess() {
            let response = await generateSSO(email)
            setEngageAccess(response.data.Success)
            setEngageSSO(response.data.Url)
        }

        async function checkPortalAccess() {
            let response = await isAdvancedPortalEnabled()
            if (response.data) {
                let status = await getStatus()
                if (status != null) {
                    setPortalAccess(response.data)
                }
            }
        }

        async function execute() {
            setShowLoading(true)
            await Promise.all([checkNabrAccess(), checkPortalAccess()])
            setShowLoading(false)
        }


        execute()
    }, [instance, user?.EmailAddress])

    const oneHandler = () => {
        setShowLoading(true)

        if (authNewFlow) {
            window.location.href = process.env.REACT_APP_ONE_URL!
        }
        else {
            let cookies = new Cookies()
            cookies.set('UserCookie', JSON.stringify(user), { expires: addHours(new Date(), 4), domain: process.env.REACT_APP_COOKIE_DOMAIN})
            let appCookie = cookies.get('.AspNet.ApplicationCookie')

            if (appCookie) {
                setShowLoading(false)
                window.location.href = process.env.REACT_APP_ONE_URL!
            } else {
                signIn(login || { username: '', password: '' }).then(() => {
                    setShowLoading(false)
                    window.location.href = process.env.REACT_APP_ONE_URL!
                }).catch((error) => {
                    setShowLoading(false)
                    alert(error)
                })
            }
        }

    }

    const portalHandler = () => {
        setShowLoading(true)
        createAdmin().then((response) => {
            if (response != null) {
                setShowLoading(false)
                window.location.href = `${process.env.REACT_APP_PORTAL_URL}?token=${authNewFlow ? getTokenStorage() : user?.Token}`;
            }
            else {
                setShowLoading(false)
                alert('Unable to login with these credentials')
            }
        })
    }

    const nabrHandler = () => {
        setShowLoading(true)

        if (engageAccess) {
            window.location.href = engageSSO
        } else {
            alert('Unable to login with these credentials')
        }
    }

    let apps: App[] = [
        {
            name: 'Central',
            description: 'Formerly TOPS [ONE], Central is your end-to-end account and operations hub.',
            logo: centralLogo,
            img: centralImage,
            url: process.env.REACT_APP_ONE_URL,
            learnMore: 'https://www.goenumerate.com/product/central',
            hasAccess: true,
            handler: oneHandler
        },
        {
            name: 'Engage',
            description: 'Formerly Nabr Network, Engage is your resident and board engagement solution.',
            logo: engageLogo,
            img: engageImage,
            url: process.env.REACT_APP_NABR_URL,
            learnMore: 'https://www.goenumerate.com/product/engage',
            hasAccess: engageAccess,
            handler: nabrHandler
        },
        {
            name: 'Portals',
            description: 'Online access and engagement tools for residents and board members.',
            logo: portalsLogo,
            img: portalsImage,
            url: process.env.REACT_APP_PORTAL_URL,
            hasAccess: portalAccess,
            handler: portalHandler
        }
    ]

    const card = (app: App, index: React.Key | null | undefined) => {
        let showCard = app.name == 'Portals' ? app.hasAccess ? true : false : true;

        let isDesktop = isPlatform('desktop');

        return showCard && (
            <IonCard key={index}>
                <div className={isDesktop ? style.logo : style.imgWrapper}>
                    <img alt={app.name} src={app.logo} />
                </div>
                {!isDesktop &&
                    <div>
                        <img alt={app.name} src={app.img} />
                    </div>
                }
                <IonCardHeader>
                    <IonCardSubtitle>{app.description}</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                    {app.hasAccess ? (
                        <IonButton shape='round' size={isDesktop ? 'default' : 'small'} onClick={app.handler}>
                            Open
                        </IonButton>
                    ) : (
                        <IonButton className={style.learnMore} fill='outline' shape='round' size={isDesktop ? 'default' : 'small'}
                            onClick={() => { window.location.href = app.learnMore! }}>
                            Learn more
                        </IonButton>
                    )
                    }
                </IonCardContent>
            </IonCard>
        );
    }

    return isPlatform('desktop') ? (
        <div className={style.appChooser}>
            <IonLoading
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Please wait...'}
            />
            <IonGrid>
                <IonRow>
                    <IonLabel className={style.slogan}>Welcome to Enumerate! Choose Your Way:</IonLabel>
                </IonRow>
                <IonRow className={style.content}>
                    <IonCol>
                        {apps.map((app, index) =>
                            card(app, index)
                        )}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    ) : (
        <div className={style.appChooserMobile}>
            <IonLoading
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Please wait...'}
            />
            <IonGrid className={style.container}>
                <IonRow>
                    <IonLabel className={style.slogan}>Welcome to Enumerate!</IonLabel>
                    <IonLabel className={style.slogan}>Choose Your Way:</IonLabel>
                </IonRow>
                <IonRow className={style.content}>
                    <IonCol size='12'>
                        {apps.map((app, index) =>
                            card(app, index)
                        )}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}

export default AppContainer;