import axios from 'axios';

async function generateSSO(userEmail?: string) {
    return axios.post(`${process.env.REACT_APP_ONE_URL}/Engage/GenerateSSO`, {
        userEmail: userEmail
    }).catch((error) => {
        throw error;
    })
}

export { generateSSO }