import React, { createContext, useState, useContext } from 'react';
import { getUserStorage } from '../utils/secure-storage';
import secureLocalStorage from "react-secure-storage";
import * as auth from '../services/auth';
import api from '../services/api';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
    children: React.ReactNode;
}

export interface Login {
    username: string,
    password: string
}

export interface User {
    EmailAddress: string
    FirstName: string
    IsAuthenticated: string
    LastName: string
    Token: string
    UserId: number
    Username: string
}

interface AuthContextData {
    signed: boolean
    authNewFlow: boolean
    user: User | null
    signIn(login: Login): Promise<void>
    signOut(): void
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<Props> = ({ children }) => {
    const [user, setUser] = useState<User | null>(getUserStorage())
    const flags = useFlags();
    const authNewFlow = flags.authNewFlow; //Flag to decide if we are going to use the new or old authentication

    async function signIn(login: Login) {
        const response = await auth.signIn(login.username, login.password)

        setUser(response.user)

        api.defaults.headers.Authorization = `Bearer ${response.user?.Token}`
        
        secureLocalStorage.setItem('user', JSON.stringify(response.user))
        secureLocalStorage.setItem('login', JSON.stringify(login))
    }

    function signOut() {
        secureLocalStorage.clear()
        setUser(null)
    }

    return (
        <AuthContext.Provider
            value={{ signed: !!user, authNewFlow, user, signIn, signOut }}>
            {children}
        </AuthContext.Provider>
    );
};

function useAuth() {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider.');
    }

    return context;
}

export { AuthProvider, useAuth };