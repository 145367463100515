import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';
import { AuthenticationResult, Configuration, EventMessage, EventType, PopupRequest, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { LDLogger } from 'launchdarkly-js-sdk-common';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

if (process.env.REACT_APP_NEW_RELIC_LICENSE_KEY !== undefined) {
  const newrelicOptions = {
      // NREUM.init
      init: {
          distributed_tracing: { enabled: true },
          privacy: { cookies_enabled: true },
          ajax: { deny_list: ["bam.nr-data.net"] }
      },
      // NREUM.info
      info: {
          beacon: "bam.nr-data.net",
          errorBeacon: "bam.nr-data.net",
          licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
          applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,
          sa: 1
      },
      // NREUM.loader_config
      loader_config: {
          accountID: process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID,
          trustKey: process.env.REACT_APP_NEW_RELIC_TRUST_KEY,
          agentID: process.env.REACT_APP_NEW_RELIC_AGENT_ID,
          licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
          applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID
      }
  }

  /*** https://github.com/newrelic/newrelic-browser-agent ***/
  new BrowserAgent(newrelicOptions)
}

// Config object to be passed to Msal on creation
const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID ?? '',
    authority: process.env.REACT_APP_AUTHORITY ?? '',
    redirectUri: "/",
    knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITY ?? ''],
    postLogoutRedirectUri: "/"
  },
  cache: {
    cacheLocation: "localStorage"
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});


const renderApp = async () => {
  var logger: LDLogger = { error: () => {}, warn: () => {}, info: () => {}, debug: () => {} };
  const container = document.getElementById('root');
  const root = createRoot(container!);
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LC_CLIENT_KEY!,
    options: {
      logger: logger
    }
  });

  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <CookiesProvider>
          <LDProvider>
            <App />
          </LDProvider>
        </CookiesProvider>
      </MsalProvider>
    </React.StrictMode>
  );
  
}
renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export const loginRequest: PopupRequest = { scopes: [process.env.REACT_APP_LOGIN_REQUEST_SCOPE!] };
